import '@/assets/scss/main.scss'
import { Theme, Reset } from '@radix-ui/themes'

export function Layout ({ children }) {
  return (
    <Reset>
      <Theme accentColor='violet' radius='large'>
        {children}
      </Theme>
    </Reset>
  )
}

export function CreateRoot (Page) {
  const rootElement = document.createElement('div')
  rootElement.setAttribute('data-react-root', '')
  document.body.appendChild(rootElement)

  ReactDOM.createRoot(rootElement).render(<Page />)
}
