import { jwtDecode } from 'jwt-decode'
import { isAfter } from 'date-fns'

const token = window.localStorage.getItem('token') || ''

function handleUnauthorized ({ redirectToHome }) {
  window.localStorage.clear()

  if (redirectToHome && window.location.href !== window.location.origin + '/') {
    window.location.replace(window.location.origin)
  }

  return {
    isAuthenticated: false,
    token: ''
  }
}

function handleAuthorized () {
  return {
    isAuthenticated: true,
    token
  }
}

export function useValidateToken ({ redirectToHome = true } = {}) {
  const unauthorized = () => handleUnauthorized({ redirectToHome })

  if (!token) {
    return unauthorized()
  }

  let decoded
  try {
    decoded = jwtDecode(token)
  } catch (error) {
    return unauthorized()
  }
  const todayInSeconds = new Date() / 1000
  const tokenExpiresSeconds = decoded.exp
  const isTokenExpired = isAfter(todayInSeconds, tokenExpiresSeconds)

  if (isTokenExpired) {
    return unauthorized()
  }

  return handleAuthorized()
}
