/**
 *  API response model
 * {
 *   "success": false,
 *   "status": "Error",
 *   "data": null,
 *   "responseMessage": "Failed to get details in SAP.",
 *   "errors": null
 * }
 */

/**
 * status
 * Unconfirmed - Show the pop-up for user to confirm dispatch date and delivery address.
 * Processing - Don't show the pop-up. Dispatch date can't be updated. Address can still be updated.
 * Order Confirmed - Dispatch date and address can't be updated. But can update Address for future order.
 */

export const fetcher = (url, { arg }) => {
  const fetchData = async () => {
    if (!url) throw new Error('URL not provided')

    if (!arg?.method) throw new Error('Method not provided')
    arg.headers ??= {}
    arg.headers['Content-Type'] ??= 'application/json'

    const response = await fetch(url, arg)

    const data = await response.json()

    // TODO: this check is temporary, need to refactor after backend updates the response model for 401
    if (response.status === 401) {
      /* eslint-disable prefer-promise-reject-errors */
      return Promise.reject({
        success: false,
        status: 'Unauthorized',
        data: null,
        responseMessage: 'Unauthorized',
        errors: null
      })
      /* eslint-enable prefer-promise-reject-errors */
    }
    if (!response.ok) {
      if (data.title) { data.responseMessage = data.title }
      return Promise.reject(data)
    }

    return Promise.resolve(data)
  }

  return fetchData()
}
